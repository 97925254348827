function Logo({ fill, className, width='70%' }) {
  return <svg width={width} viewBox="0 0 573 56" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path d="M112.709 43V0.608032H119.037V43H112.709Z" fill={fill}/>
    <path d="M62.3085 7.16003V43H68.8605V7.16003H79.7805V1.28003H51.2205V7.16003H62.3085Z" fill={fill}/>
    <path d="M85.8324 42.16C87.5498 43.168 89.5471 43.672 91.8244 43.672C94.0271 43.672 95.9498 43.168 97.5924 42.16C98.7942 41.4226 99.7462 40.4553 100.448 39.2583V43H106.44V12.704H100.168V30.568C100.168 32.024 99.8698 33.2934 99.2724 34.376C98.7124 35.4587 97.9284 36.2987 96.9204 36.896C95.9124 37.4934 94.7364 37.792 93.3924 37.792C92.0858 37.792 90.9284 37.512 89.9204 36.952C88.9124 36.3547 88.1284 35.5334 87.5684 34.488C87.0084 33.4427 86.7284 32.2294 86.7284 30.848V12.704H80.4004V31.408C80.4004 33.8347 80.8671 35.9814 81.8004 37.848C82.7711 39.6774 84.1151 41.1147 85.8324 42.16Z" fill={fill}/>
    <path d="M128.339 41.376C130.691 42.9067 133.417 43.672 136.516 43.672C138.755 43.672 140.715 43.2987 142.395 42.552C144.075 41.768 145.382 40.704 146.315 39.36C147.286 37.9787 147.771 36.392 147.771 34.6C147.771 32.5094 147.099 30.7174 145.755 29.224C144.411 27.6934 142.489 26.5734 139.987 25.864L135.059 24.408C134.425 24.2214 133.809 23.9974 133.211 23.736C132.614 23.4374 132.129 23.064 131.755 22.616C131.382 22.1307 131.195 21.5334 131.195 20.824C131.195 19.7787 131.606 18.9387 132.427 18.304C133.249 17.632 134.35 17.296 135.731 17.296C137.337 17.296 138.774 17.7254 140.043 18.584C141.35 19.4427 142.302 20.6 142.899 22.056L147.771 19.76C146.875 17.3334 145.345 15.448 143.179 14.104C141.014 12.7227 138.55 12.032 135.787 12.032C133.734 12.032 131.886 12.4054 130.243 13.152C128.601 13.8987 127.313 14.9627 126.379 16.344C125.446 17.688 124.979 19.2747 124.979 21.104C124.979 23.12 125.614 24.8934 126.883 26.424C128.19 27.9174 130.15 29.0374 132.763 29.784L137.523 31.128C138.158 31.2774 138.774 31.5014 139.371 31.8C140.006 32.0987 140.529 32.4907 140.939 32.976C141.35 33.424 141.555 34.0214 141.555 34.768C141.555 35.9254 141.089 36.84 140.155 37.512C139.222 38.184 138.009 38.52 136.516 38.52C134.761 38.52 133.155 38.016 131.699 37.008C130.281 36 129.161 34.6374 128.339 32.92L123.467 35.216C124.401 37.792 126.025 39.8454 128.339 41.376Z" fill={fill}/>
    <path fillRule="evenodd" clipRule="evenodd" d="M161.924 43.672C159.87 43.672 158.06 43.3174 156.492 42.608C154.961 41.8614 153.766 40.8534 152.908 39.584C152.049 38.2774 151.62 36.7467 151.62 34.992C151.62 33.3494 151.974 31.8747 152.684 30.568C153.43 29.2614 154.569 28.16 156.1 27.264C157.63 26.368 159.553 25.7334 161.868 25.36L171.5 23.7718V22.616C171.5 21.1227 170.94 19.9094 169.82 18.976C168.737 18.0054 167.281 17.52 165.452 17.52C163.772 17.52 162.297 17.968 161.028 18.864C159.796 19.7227 158.881 20.8427 158.284 22.224L153.02 19.592C153.58 18.0987 154.494 16.792 155.764 15.672C157.033 14.5147 158.508 13.6187 160.188 12.984C161.905 12.3494 163.716 12.032 165.62 12.032C168.009 12.032 170.118 12.48 171.948 13.376C173.814 14.272 175.252 15.5227 176.26 17.128C177.305 18.696 177.828 20.5254 177.828 22.616V43H171.78V39.4827C171.341 39.9836 170.855 40.4468 170.324 40.872C169.241 41.768 167.99 42.4587 166.572 42.944C165.19 43.4294 163.641 43.672 161.924 43.672ZM171.5 30.4V28.7646L163.1 30.232C161.42 30.5307 160.188 31.072 159.404 31.856C158.62 32.6027 158.228 33.5734 158.228 34.768C158.228 35.9254 158.657 36.8774 159.516 37.624C160.412 38.3334 161.55 38.688 162.932 38.688C164.649 38.688 166.142 38.3147 167.412 37.568C168.718 36.8214 169.726 35.832 170.436 34.6C171.145 33.3307 171.5 31.9307 171.5 30.4Z" fill={fill}/>
    <path fillRule="evenodd" clipRule="evenodd" d="M193.665 1.28003V43H200.273V27.32H208.841C211.641 27.32 214.105 26.7974 216.233 25.752C218.361 24.7067 220.023 23.2134 221.217 21.272C222.449 19.3307 223.065 17.016 223.065 14.328C223.065 11.6027 222.468 9.26937 221.273 7.32803C220.079 5.34937 218.399 3.85603 216.233 2.84803C214.105 1.8027 211.641 1.28003 208.841 1.28003H193.665ZM209.009 21.44H200.273V7.16003H209.009C210.503 7.16003 211.809 7.4587 212.929 8.05603C214.049 8.61603 214.927 9.43736 215.561 10.52C216.196 11.5654 216.513 12.816 216.513 14.272C216.513 15.728 216.196 16.9974 215.561 18.08C214.927 19.1254 214.049 19.9467 212.929 20.544C211.809 21.1414 210.503 21.44 209.009 21.44Z" fill={fill}/>
    <path fillRule="evenodd" clipRule="evenodd" d="M240.222 43.672C237.31 43.672 234.64 42.9814 232.214 41.6C229.824 40.2187 227.92 38.3334 226.502 35.944C225.083 33.5547 224.374 30.848 224.374 27.824C224.374 24.7627 225.083 22.056 226.502 19.704C227.92 17.3147 229.824 15.448 232.214 14.104C234.603 12.7227 237.272 12.032 240.222 12.032C243.208 12.032 245.878 12.7227 248.23 14.104C250.619 15.448 252.504 17.3147 253.886 19.704C255.304 22.056 256.014 24.7627 256.014 27.824C256.014 30.8854 255.304 33.6107 253.886 36C252.467 38.3894 250.563 40.2747 248.174 41.656C245.784 43 243.134 43.672 240.222 43.672ZM240.222 37.792C242.014 37.792 243.6 37.3627 244.982 36.504C246.363 35.6454 247.446 34.4694 248.23 32.976C249.051 31.4454 249.462 29.728 249.462 27.824C249.462 25.92 249.051 24.2214 248.23 22.728C247.446 21.2347 246.363 20.0587 244.982 19.2C243.6 18.3414 242.014 17.912 240.222 17.912C238.467 17.912 236.88 18.3414 235.462 19.2C234.08 20.0587 232.979 21.2347 232.158 22.728C231.374 24.2214 230.982 25.92 230.982 27.824C230.982 29.728 231.374 31.4454 232.158 32.976C232.979 34.4694 234.08 35.6454 235.462 36.504C236.88 37.3627 238.467 37.792 240.222 37.792Z" fill={fill}/>
    <path d="M261.072 0.608032V43H267.4V0.608032H261.072Z" fill={fill}/>
    <path d="M273.678 43V12.704H280.006V43H273.678Z" fill={fill}/>
    <path d="M273.678 8.56003V1.28003H280.006V8.56003H273.678Z" fill={fill}/>
    <path d="M292.613 41.6C294.965 42.9814 297.653 43.672 300.677 43.672C302.768 43.672 304.709 43.2987 306.501 42.552C308.293 41.8054 309.842 40.7974 311.149 39.528C312.493 38.2214 313.464 36.7654 314.061 35.16L308.517 32.528C307.845 34.1334 306.818 35.4214 305.437 36.392C304.093 37.3254 302.506 37.792 300.677 37.792C298.96 37.792 297.41 37.3627 296.029 36.504C294.685 35.6454 293.621 34.4694 292.837 32.976C292.053 31.4454 291.661 29.728 291.661 27.824C291.661 25.92 292.053 24.2214 292.837 22.728C293.621 21.1974 294.685 20.0214 296.029 19.2C297.41 18.3414 298.96 17.912 300.677 17.912C302.469 17.912 304.056 18.3974 305.437 19.368C306.856 20.3014 307.882 21.552 308.517 23.12L314.061 20.544C313.426 18.864 312.437 17.3894 311.093 16.12C309.786 14.8507 308.237 13.8614 306.445 13.152C304.653 12.4054 302.73 12.032 300.677 12.032C297.653 12.032 294.965 12.7227 292.613 14.104C290.261 15.448 288.413 17.3147 287.069 19.704C285.725 22.0934 285.053 24.7814 285.053 27.768C285.053 30.792 285.725 33.4987 287.069 35.888C288.45 38.2774 290.298 40.1814 292.613 41.6Z" fill={fill}/>
    <path d="M321.814 55.376C321.067 55.376 320.32 55.3014 319.574 55.152C318.865 55.04 318.211 54.8534 317.614 54.592V49.16C318.062 49.272 318.603 49.3654 319.238 49.44C319.872 49.552 320.488 49.608 321.086 49.608C322.84 49.608 324.128 49.216 324.95 48.432C325.808 47.6854 326.574 46.5094 327.246 44.904L328.093 42.9753L316.046 12.704H322.822L331.358 34.9586L339.846 12.704H346.678L333.294 46.304C332.585 48.096 331.688 49.664 330.606 51.008C329.56 52.3894 328.31 53.4534 326.854 54.2C325.435 54.984 323.755 55.376 321.814 55.376Z" fill={fill}/>
    <path d="M359.447 1.28003V43H366.055V1.28003H359.447Z" fill={fill}/>
    <path d="M373.242 43V12.704H379.29V16.374C380.009 15.1877 380.961 14.2444 382.146 13.544C383.789 12.536 385.693 12.032 387.858 12.032C390.098 12.032 392.077 12.5174 393.794 13.488C395.512 14.4587 396.856 15.8027 397.826 17.52C398.797 19.2374 399.282 21.216 399.282 23.456V43H393.01V25.136C393.01 23.6054 392.73 22.3174 392.17 21.272C391.61 20.1894 390.808 19.368 389.762 18.808C388.754 18.2107 387.597 17.912 386.29 17.912C384.984 17.912 383.808 18.2107 382.762 18.808C381.754 19.368 380.97 20.1894 380.41 21.272C379.85 22.3547 379.57 23.6427 379.57 25.136V43H373.242Z" fill={fill}/>
    <path d="M407.959 41.376C410.311 42.9067 413.036 43.672 416.135 43.672C418.375 43.672 420.335 43.2987 422.015 42.552C423.695 41.768 425.002 40.704 425.935 39.36C426.906 37.9787 427.391 36.392 427.391 34.6C427.391 32.5094 426.719 30.7174 425.375 29.224C424.031 27.6934 422.108 26.5734 419.607 25.864L414.679 24.408C414.044 24.2214 413.428 23.9974 412.831 23.736C412.234 23.4374 411.748 23.064 411.375 22.616C411.002 22.1307 410.815 21.5334 410.815 20.824C410.815 19.7787 411.226 18.9387 412.047 18.304C412.868 17.632 413.97 17.296 415.351 17.296C416.956 17.296 418.394 17.7254 419.663 18.584C420.97 19.4427 421.922 20.6 422.519 22.056L427.391 19.76C426.495 17.3334 424.964 15.448 422.799 14.104C420.634 12.7227 418.17 12.032 415.407 12.032C413.354 12.032 411.506 12.4054 409.863 13.152C408.22 13.8987 406.932 14.9627 405.999 16.344C405.066 17.688 404.599 19.2747 404.599 21.104C404.599 23.12 405.234 24.8934 406.503 26.424C407.81 27.9174 409.77 29.0374 412.383 29.784L417.143 31.128C417.778 31.2774 418.394 31.5014 418.991 31.8C419.626 32.0987 420.148 32.4907 420.559 32.976C420.97 33.424 421.175 34.0214 421.175 34.768C421.175 35.9254 420.708 36.84 419.775 37.512C418.842 38.184 417.628 38.52 416.135 38.52C414.38 38.52 412.775 38.016 411.319 37.008C409.9 36 408.78 34.6374 407.959 32.92L403.087 35.216C404.02 37.792 405.644 39.8454 407.959 41.376Z" fill={fill}/>
    <path d="M445.575 43.336C442.402 43.336 439.938 42.44 438.183 40.648C436.428 38.856 435.551 36.336 435.551 33.088V18.416H430.231V12.704H431.071C432.49 12.704 433.591 12.2934 434.375 11.472C435.159 10.6507 435.551 9.5307 435.551 8.11203V5.76003H441.879V12.704H448.767V18.416H441.879V32.808C441.879 33.8534 442.047 34.7494 442.383 35.496C442.719 36.2054 443.26 36.7654 444.007 37.176C444.754 37.5494 445.724 37.736 446.919 37.736C447.218 37.736 447.554 37.7174 447.927 37.68C448.3 37.6427 448.655 37.6054 448.991 37.568V43C448.468 43.0747 447.89 43.1494 447.255 43.224C446.62 43.2987 446.06 43.336 445.575 43.336Z" fill={fill}/>
    <path d="M454.046 12.704V43H460.374V12.704H454.046Z" fill={fill}/>
    <path d="M454.046 1.28003V8.56003H460.374V1.28003H454.046Z" fill={fill}/>
    <path d="M479.757 43.336C476.584 43.336 474.12 42.44 472.365 40.648C470.61 38.856 469.733 36.336 469.733 33.088V18.416H464.413V12.704H465.253C466.672 12.704 467.773 12.2934 468.557 11.472C469.341 10.6507 469.733 9.5307 469.733 8.11203V5.76003H476.061V12.704H482.949V18.416H476.061V32.808C476.061 33.8534 476.229 34.7494 476.565 35.496C476.901 36.2054 477.442 36.7654 478.189 37.176C478.936 37.5494 479.906 37.736 481.101 37.736C481.4 37.736 481.736 37.7174 482.109 37.68C482.482 37.6427 482.837 37.6054 483.173 37.568V43C482.65 43.0747 482.072 43.1494 481.437 43.224C480.802 43.2987 480.242 43.336 479.757 43.336Z" fill={fill}/>
    <path d="M493.044 42.16C494.762 43.168 496.759 43.672 499.036 43.672C501.239 43.672 503.162 43.168 504.804 42.16C506.006 41.4226 506.958 40.4553 507.66 39.2583V43H513.652V12.704H507.38V30.568C507.38 32.024 507.082 33.2934 506.484 34.376C505.924 35.4587 505.14 36.2987 504.132 36.896C503.124 37.4934 501.948 37.792 500.604 37.792C499.298 37.792 498.14 37.512 497.132 36.952C496.124 36.3547 495.34 35.5334 494.78 34.488C494.22 33.4427 493.94 32.2294 493.94 30.848V12.704H487.612V31.408C487.612 33.8347 488.079 35.9814 489.012 37.848C489.983 39.6774 491.327 41.1147 493.044 42.16Z" fill={fill}/>
    <path d="M533.025 43.336C529.851 43.336 527.387 42.44 525.633 40.648C523.878 38.856 523.001 36.336 523.001 33.088V18.416H517.681V12.704H518.521C519.939 12.704 521.041 12.2934 521.825 11.472C522.609 10.6507 523.001 9.5307 523.001 8.11203V5.76003H529.329V12.704H536.217V18.416H529.329V32.808C529.329 33.8534 529.497 34.7494 529.833 35.496C530.169 36.2054 530.71 36.7654 531.457 37.176C532.203 37.5494 533.174 37.736 534.369 37.736C534.667 37.736 535.003 37.7174 535.377 37.68C535.75 37.6427 536.105 37.6054 536.441 37.568V43C535.918 43.0747 535.339 43.1494 534.705 43.224C534.07 43.2987 533.51 43.336 533.025 43.336Z" fill={fill}/>
    <path fillRule="evenodd" clipRule="evenodd" d="M547.109 41.6C549.461 42.9814 552.149 43.672 555.173 43.672C557.227 43.672 559.112 43.3547 560.829 42.72C562.584 42.048 564.077 41.152 565.309 40.032C566.579 38.8747 567.512 37.5867 568.109 36.168L562.957 33.648C562.211 34.9547 561.184 36.0187 559.877 36.84C558.608 37.6614 557.059 38.072 555.229 38.072C553.437 38.072 551.832 37.6427 550.413 36.784C549.032 35.9254 547.949 34.7307 547.165 33.2C546.652 32.1476 546.359 30.9716 546.287 29.672H568.837C568.949 29.224 569.024 28.7387 569.061 28.216C569.099 27.6934 569.117 27.1894 569.117 26.704C569.117 24.7254 568.781 22.8587 568.109 21.104C567.475 19.3494 566.541 17.8 565.309 16.456C564.077 15.0747 562.565 13.992 560.773 13.208C558.981 12.424 556.947 12.032 554.669 12.032C551.832 12.032 549.293 12.7227 547.053 14.104C544.813 15.448 543.021 17.296 541.677 19.648C540.371 22 539.717 24.7067 539.717 27.768C539.717 30.7547 540.371 33.4614 541.677 35.888C542.984 38.2774 544.795 40.1814 547.109 41.6ZM546.445 24.632C546.588 23.7536 546.828 22.9509 547.165 22.224C547.875 20.656 548.883 19.4614 550.189 18.64C551.496 17.7814 552.989 17.352 554.669 17.352C556.349 17.352 557.805 17.7814 559.037 18.64C560.307 19.4614 561.24 20.6 561.837 22.056C562.193 22.8503 562.39 23.709 562.429 24.632H546.445Z" fill={fill}/>
    <path d="M17.5 1.75003L1 43H9.1L25.6 1.75003H17.5Z" fill={fill}/>
    <path d="M33.1113 1.75003L16.6113 43H24.7113L41.2113 1.75003H33.1113Z" fill={fill}/>
  </svg>;
}

export default Logo;
